<template>
  <div class="follow-wrapper bj">
    <div class="title">
      <div class="seach order-wrapper">
        <div class="inputs">
          <span class="label">创建日期：</span>
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            v-model="createDate"
            @change="changeCreateDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <span class="label">完成日期：</span>
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            v-model="finishDate"
            @change="changeFinishDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <span class="label">支付日期：</span>
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            v-model="payDate"
            @change="changePayDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <span class="label">供货日期：</span>
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            v-model="supplierDate"
            @change="changeSupplierDate"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <!-- <span class="label">订单号：</span> -->
          <el-input
            class="jn-input-style"
            v-model="formData.order_no"
            placeholder="请输入订单号"
            clearable
          ></el-input>
        </div>

        <div class="inputs">
          <!-- <span class="label">售后单号：</span> -->
          <el-input
            class="jn-input-style"
            v-model="formData.as_no"
            placeholder="请输入售后单号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <!-- <span class="label">客户电话：</span> -->
          <el-input
            class="jn-input-style"
            v-model="formData.mobile"
            placeholder="请输入客户电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <!-- <span class="label">商品名称：</span> -->
          <el-input
            class="jn-input-style"
            v-model="formData.title"
            placeholder="请输入商品名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <!-- <span class="label">货号：</span> -->
          <el-input
            class="jn-input-style"
            v-model="formData.sku_code"
            placeholder="请输入货号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <!-- <span class="label">二级分类：</span> -->
          <el-select
            class="jn-input-style"
            v-model="formData.cid"
            clearable
            filterable
            placeholder="请选择二级分类"
          >
            <el-option
              v-for="item in subCidList"
              :label="item.name"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <div class="inputs">
          <!-- <span class="label">售后原因：</span> -->
          <el-select
            class="jn-input-style"
            v-model="formData.reason_type"
            clearable
            placeholder="请选择售后原因"
          >
            <el-option
              v-for="item in reasonList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <!-- <span class="label">集配中心：</span> -->
          <el-select
            class="jn-input-style"
            v-model="formData.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of businessList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <!-- <span class="label">审批状态：</span> -->
          <el-select
            class="jn-input-style"
            v-model="formData.status"
            clearable
            placeholder="请选择审批状态"
          >
            <el-option
              v-for="item of auditStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <!-- <span class="label">城市仓：</span> -->
          <el-select
            class="jn-input-style"
            placeholder="选择城市仓"
            v-model="formData.logistics_id"
            @change="onChangeCityStore"
            :filter-method="(val) => onFilterMethod('cityStore', val)"
            @visible-change="(val) => onChangeVisible('cityStore', val)"
            filterable
            clearable
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <!-- <span class="label">自提点：</span> -->
          <el-select
            class="jn-input-style"
            placeholder="选择自提点"
            v-model="formData.delivery_id"
            :filter-method="(val) => onFilterMethod('selfTake', val)"
            @visible-change="(val) => onChangeVisible('selfTake', val)"
            filterable
            clearable
          >
            <el-option
              v-for="item in selfTakeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <!-- <span class="label">业务员：</span> -->
          <el-input
            class="jn-input-style"
            v-model="formData.buyer_name"
            placeholder="请输入业务员姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <!-- <span class="label">供应商：</span> -->
          <el-input
            class="jn-input-style"
            v-model="formData.supplier_name"
            placeholder="请输入供应商名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <!-- <span class="label">审批状态：</span> -->
          <el-select
            class="jn-input-style"
            v-model="formData.is_tdc_submit"
            clearable
            placeholder="是否城市仓提报"
          >
            <el-option
              v-for="item of isTdcSubmitList"
              :key="item.value"
              :label="item.label"
              :value="item.value + ''"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            class="jn-input-style"
            v-model="formData.supplier_order_no"
            placeholder="请输入供货单号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            class="jn-input-style"
            v-model="formData.membership_status"
            clearable
            placeholder="请选择用户类型"
          >
            <el-option
              v-for="item of customerTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value + ''"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            class="jn-input-style"
            v-model="formData.is_tdc_owner"
            clearable
            placeholder="是否仓主专区商品"
          >
            <el-option
              v-for="item of warehouseList"
              :key="item.value"
              :label="item.label"
              :value="item.value + ''"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-autocomplete
                    class="jn-input-style"
                    v-model="formData.team_name"
                    :fetch-suggestions="
                        querySearchAsync
                    "
                    placeholder="请输入组名"
                  ></el-autocomplete>
        </div>
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="onHandleRefresh"
        ></el-button>
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column
          prop="logistic_business_name"
          min-width="150"
          show-overflow-tooltip
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.logistic_business_name || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="supplier_name"
          min-width="150"
          show-overflow-tooltip
          align="center"
          label="供货商"
        >
          <template slot-scope="scope">
            <span>{{ getSupplierName(scope.row.aftersale) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="buyer_name" align="center" label="业务员">
          <template slot-scope="scope">
            <span>{{ getSupplierBuyerName(scope.row.aftersale) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="logistics_name" align="center" label="城市仓">
          <template slot-scope="scope">
            <span>{{ getLogisticsName(scope.row.aftersale) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="delivery_name"
          width="150"
          show-overflow-tooltip
          align="center"
          label="自提点"
        >
          <template slot-scope="scope">
            <span>{{ getDeliveryName(scope.row.aftersale) }}</span>
          </template>
        </el-table-column>
       
        <el-table-column
          prop="order_no"
          width="180"
          align="center"
          label="订单号"
        >
          <template slot-scope="scope">
            <span>{{
              (scope.row.aftersale && scope.row.aftersale.order_no) || "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="clientele_services_team_name"
          width="180"
          align="center"
          label="组名"
        >
          
        </el-table-column>
        <el-table-column prop="member_name" align="center" label="客户名称">
          <template slot-scope="scope">
            {{ getMemberName(scope.row.aftersale) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="membership_status"
          align="center"
          label="客户类型"
        >
          <template slot-scope="scope">
            <span>{{
              customerTypeList.find(
                (item) =>
                  item.value ===
                  Number(scope.row?.aftersale?.order?.membership_status)
              )?.label || "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="member_mobile"
          width="150"
          align="center"
          label="客户电话"
        >
          <template slot-scope="scope">
            {{ getMemberMobile(scope.row.aftersale) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="as_no"
          width="180"
          align="center"
          label="售后单号"
        >
          <template slot-scope="scope">
            <span>{{
              (scope.row.aftersale && scope.row.aftersale.as_no) || "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          align="center"
          width="150"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ scope.row.create_time || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="supplier_create_at"
          align="center"
          width="150"
          label="供货日期"
        >
        </el-table-column>
        <el-table-column
          prop="goods_name"
          align="center"
          width="150"
          label="商品名称"
        >
          <template slot-scope="scope">
            {{ getGoodsName(scope.row.aftersale) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sku_code"
          align="center"
          width="150"
          label="货号"
        >
        </el-table-column>
        <el-table-column
          prop="is_tdc_owner_txt"
          align="center"
          width="150"
          label="是否仓主专区"
        >
        </el-table-column>
        <el-table-column
          prop="cate_name"
          align="center"
          width="150"
          label="二级分类"
        ></el-table-column>

        <el-table-column
          prop="reason_name"
          align="center"
          width="150"
          label="售后原因"
        >
          <template slot-scope="scope">
            <span>{{ getReason(scope.row.aftersale) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_tdc_submit"
          width="180"
          align="center"
          label="城市仓提报"
        >
          <template slot-scope="scope">
            <span>{{
              isTdcSubmitList.find(
                (item) => item.value === scope.row?.aftersale?.is_tdc_submit
              )?.label || "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="status_name"
          width="150"
          align="center"
          label="审批状态"
        >
          <template slot-scope="scope">
            <el-tag
              type="warning"
              v-if="scope.row.status === AUDIT_STATUS_ENUM.ing.value"
              >{{ AUDIT_STATUS_ENUM.ing.label }}</el-tag
            >
            <el-tag
              type="success"
              v-if="scope.row.status === AUDIT_STATUS_ENUM.return.value"
              >{{ AUDIT_STATUS_ENUM.return.label }}</el-tag
            >
            <el-tag
              type="danger"
              v-if="scope.row.status === AUDIT_STATUS_ENUM.reject.value"
              >{{ AUDIT_STATUS_ENUM.reject.label }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="150"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.status === AUDIT_STATUS_ENUM.ing.value"
              :to="{
                name: 'newAfterCheck',
                query: { id: scope.row.as_id, state: scope.row.state },
              }"
              class="main-color pointer text-none"
              >去审批
            </router-link>
            <router-link
              v-else
              :to="{
                name: 'newAfterDetail',
                query: { id: scope.row.as_id, state: scope.row.state },
              }"
              class="main-color pointer text-none"
              >查看详情
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { getSubList } from "@/api/generalControl/index.js";
// import { getAfterSaleNewExport } from "@/api/export/index";
// import { downLoadFlowBlob } from "@/utils/tools/base";
import {
  AUDIT_STATUS_ENUM,
  LOSS_REASON_ENUM,
  IS_TDC_SUBMIT,
  CUSTOMER_TYPE_ENUM,
  WAREHOUSE_TYPE_ENUM,
} from "./utils/enum/index";
import { postAfterSaleExport } from "@/api/export/center";
import { formatDate } from "@/utils/tools/date.js";
import { values } from "lodash";

export default {
  name: "PlatformParticipation",
  data() {
    return {
      loading: false,
      AUDIT_STATUS_ENUM,
      warehouseList: Object.values(WAREHOUSE_TYPE_ENUM), // 是否为仓主专区
      auditStatusList: Object.values(AUDIT_STATUS_ENUM), // 审核状态列表
      isTdcSubmitList: Object.values(IS_TDC_SUBMIT), // 是否城市仓提报
      customerTypeList: Object.values(CUSTOMER_TYPE_ENUM), // 用户类型
      reasonList: Object.values(LOSS_REASON_ENUM), // 售后原因
      subCidList: [], // 二级分类列表
      businessList: [], // 集配中心列表
      cityStoreList: [], // 城市仓列表
      selfTakeList: [], // 自提点列表
      createDate: [], // 售后创建日期对象
      finishDate: [], // 售后完成日期对象
      payDate: [], // 订单支付时间对象
      supplierDate: [], // 供货日期
      formData: {
        supplier_order_no: "", //供货单号
        membership_status: "", // 会员类型
        is_tdc_owner: "", // 是否为仓主专区
        create_time: "", // 售后创建日期
        // create_end_time: "", // 售后创建结束日期
        operator_at: "", // 售后完成日期
        // finish_end_time: "", // 售后完成结束日期
        pay_at: "", // 订单支付日期
        // pay_end_time: "", // 订单支付结束日期
        supplier_time: "", // 供货日期
        // supplier_end_time: "", // 供货结束日期
        order_no: "", // 订单号
        as_no: "", // 售后单号
        mobile: "", // 客户电话
        title: "", // 商品名称
        sku_code: "", // 货号
        cid: "", // 二级分类
        reason_type: "", // 售后原因
        logistic_business_id: "", // 集配中心
        status: "", // 审批状态
        logistics_id: "", // 城市仓
        delivery_id: "", // 自提点
        buyer_name: "", // 业务员
        supplier_name: "", // 供应商
        is_tdc_submit: "", // 是否城市仓提报
        team_name:'',//
        page: 1,
        pageSize: 10,
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
      // 时间范围
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      disabledExport: false, // 导出禁用
    };
  },
  computed: {
    /**
     * 获得供货商名称
     */
    getSupplierName() {
      return (data) => {
        const res = data?.aftersale_detail?.order_detail?.supplier?.title;
        return res || "-";
      };
    },
    /**
     * 获得业务员名称
     */
    getSupplierBuyerName() {
      return (data) => {
        const res = data?.aftersale_detail?.product?.buyer?.fullname;
        return res || "-";
      };
    },
    /**
     * 获得城市仓名称
     */
    getLogisticsName() {
      return (data) => {
        const res = data?.order?.store_delivery?.logistics?.name;
        return res || "-";
      };
    },
    /**
     * 获得自提点名称
     */
    getDeliveryName() {
      return (data) => {
        const res = data?.order?.store_delivery?.name;
        return res || "-";
      };
    },
    /**
     * 获得客户名称
     */
    getMemberName() {
      return (data) => {
        const res = data?.member?.fullname;
        return res || "-";
      };
    },
    /**
     * 获得客服电话
     */
    getMemberMobile() {
      return (data) => {
        const res = data?.member?.mobile;
        return res || "-";
      };
    },
    /**
     * 获得商品名称
     */
    getGoodsName() {
      return (data) => {
        const res = data?.aftersale_detail?.order_detail?.title;
        return res || "-";
      };
    },
    /**
     * 获得售后原因
     */
    getReason() {
      return (data) => {
        const type = data?.aftersale_detail?.reason_type;
        const res =
          type && this.reasonList.find((item) => item.value === type)?.label;
        return res || "-";
      };
    },
  },
  mounted() {
    this.supplierDate = [this.getThreeMonthAgoDate(), formatDate()];
    this.formData.supplier_time = `${this.supplierDate?.[0] || ""},${
      this.supplierDate?.[1] || ""
    }`;
    this.formDataClone = cloneDeep(this.formData);
    // 路径有参数 搜索数据回显
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.formData = url;
      // 供货日期
      this.supplierDate = this.formData.supplier_time
        ? this.formData.supplier_time
        : [];
      this.formData.supplier_time =
        this.formData.supplier_time.length > 0
          ? this.formData.supplier_time.join(",")
          : "";
      // 支付日期
      this.payDate = this.formData.pay_at ? this.formData.pay_at : [];
      this.formData.pay_at =
        this.formData.pay_at.length > 0 ? this.formData.pay_at.join(",") : "";
      // 创建日期
      this.createDate = this.formData.create_time
        ? this.formData.create_time
        : [];
      this.formData.create_time =
        this.formData.create_time.length > 0
          ? this.formData.create_time.join(",")
          : "";
      // 完成日期
      this.finishDate = this.formData.operator_at
        ? this.formData.operator_at
        : [];
      this.formData.operator_at =
        this.formData.operator_at.length > 0
          ? this.formData.operator_at.join(",")
          : "";
      //页数和页码
      this.formData.page = Number(this.formData.page);
      this.formData.pageSize = Number(this.formData.pageSize);
      // 城市仓和自提点
      if (this.formData.logistics_id) {
        this.formData.logistics_id = Number(this.formData.logistics_id);
        const params = {
          name: "",
          logisticsId: this.formData.logistics_id,
        };
        this.ajaxGetSelfTakeList(params); // 请求自提点列表
        this.formData.delivery_id = this.formData.delivery_id
          ? Number(this.formData.delivery_id)
          : ""; //
        this.cityStoreList = this.formData.cityStoreList; //回显选中城市仓列表
        this.selfTakeList = this.formData.selfTakeList; // 回显选中自提点列表
        delete this.formData.cityStoreList;
        delete this.formData.selfTakeList;
      } else {
        delete this.formData.cityStoreList;
        delete this.formData.selfTakeList;
      }
      this.formData.status = this.formData.status
        ? Number(this.formData.status)
        : ""; // 审批状态
      this.formData.logistic_business_id = this.formData.logistic_business_id
        ? Number(this.formData.logistic_business_id)
        : ""; // 集配中心
      this.businessList = this.formData.businessList; // 回显集配中心列表
      delete this.formData.businessList;
      this.formData.reason_type = this.formData.reason_type
        ? Number(this.formData.reason_type)
        : ""; //售后原因
      this.subCidList = this.formData.subCidList; // 回显分类列表
      delete this.formData.subCidList;
      this.formData.cid = this.formData.cid ? Number(this.formData.cid) : ""; // 二级分类
      console.log(this.formData, url, "===");
    }
    this.onInitData();
  },
  methods: {
    querySearchAsync( queryString, cb) {
      if (this.formData.team_name !== "") {
       
        this.$api.customer
          .servicesteamlist({
            team_name: this.formData.team_name, //	是	pi模糊查询
            page:1,pageSize:999999999999            
          })
          .then((res) => {
            // this.typemethod(type, queryString, res, i);
            console.log(res.data.data, "res.data.data")
let arr=res.data.data.map(el=>{
  return{
    value:el.team_name
  }
})
            cb(arr ? arr : []);
          });
      } else{
        cb([]);
      }
    },
    // 获取近3月
    getThreeMonthAgoDate() {
      const today = new Date();
      const threeMonthsAgo = new Date(today.setMonth(today.getMonth() - 3));
      return formatDate(threeMonthsAgo);
    },
    /**
     * 初始化数据
     */
    onInitData() {
      this.ajaxGetSaleList();
      this.ajaxGetCityStoreList();
      this.ajaxGetBusinessList();
      this.ajaxGetSubList();
    },
    /**
     * 获得集配中心列表
     */
    async ajaxGetBusinessList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.businessList = data;
        console.log("ajax ajaxGetBusinessList", data);
      } catch (err) {
        console.log("ajax ajaxGetBusinessList err", err);
      }
    },
    /**
     * 获取二级分类列表
     */
    async ajaxGetSubList() {
      try {
        const { data } = await getSubList();
        this.subCidList = data;
        console.log("ajax ajaxGetSubList", data);
      } catch (err) {
        console.log("ajax ajaxGetSubList err", err);
      }
    },
    /**
     * 搜索参数添加到路径
     */
    redirectPath() {
      let tmpData = { ...this.formData };
      tmpData.supplier_time = this.formData.supplier_time
        ? this.supplierDate
        : [];
      tmpData.operator_at = this.formData.operator_at ? this.finishDate : [];
      tmpData.create_time = this.formData.create_time ? this.createDate : [];
      tmpData.pay_at = this.formData.pay_at ? this.payDate : [];
      let arr = this.subCidList.filter((item) => item.id == this.formData.cid);
      tmpData.subCidList = arr; // 获取选中分类列表
      let arr1 = this.businessList.filter(
        (item) => item.id == this.formData.logistic_business_id
      );
      tmpData.businessList = arr1; // 获取选中集配中心列表
      let arr2 = this.cityStoreList.filter(
        (item) => item.id == this.formData.logistics_id
      );
      let arr3 = [];
      if (this.formData.logistics_id) {
        arr3 = this.selfTakeList.filter(
          (item) => item.id == this.formData.delivery_id
        );
      }
      tmpData.cityStoreList = arr2; // 获取选中城市仓列表
      tmpData.selfTakeList = arr3; // 获取选中自提点列表
      this.tool.getResult(tmpData, window.location.href);
      console.log(this.formData, tmpData, "=555==");
    },
    /**
     * 查询表单
     */
    async onHandleSearch() {
      // 重置数据第一页
      this.formData.page = 1;
      this.redirectPath();
      this.ajaxGetSaleList();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.supplierDate = [this.getThreeMonthAgoDate(), formatDate()];
      this.formData.supplier_time = `${this.supplierDate?.[0] || ""},${
        this.supplierDate?.[1] || ""
      }`;
      this.formData = {
        ...this.formDataClone,
      };
      this.selfTakeList = [];
      this.createDate = [];
      this.finishDate = [];
      this.payDate = [];
      // this.supplierDate = [];
      this.redirectPath();
      this.ajaxGetSaleList();
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      /** 旧导出逻辑 */
      // this.disabledExport = true;
      // try {
      //   const params = {
      //     ...this.formData,
      //   };
      //   delete params.page;
      //   delete params.pageSize;
      //   const res = await getAfterSaleNewExport(params);
      //   downLoadFlowBlob(res, res?.filename);
      // } catch (err) {
      //   this.$message.error("导出失败了");
      //   console.log("ajax getAfterSaleNewExport err", err);
      // } finally {
      //   this.disabledExport = false;
      // }
      /** 新导出逻辑 */
      this.disabledExport = true;
      try {
        const query = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postAfterSaleExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postAfterSaleExport err", err);
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.redirectPath();
      this.ajaxGetSaleList();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.redirectPath();
      this.ajaxGetSaleList();
    },
    /**
     * 获得列表数据
     */
    async ajaxGetSaleList() {
      this.loading = true;
      const params = {
        ...this.formData,
      };
      try {
        const { data } = await this.$api.general.salenewlist(params);
        const { total, data: list } = data;
        this.tableData = list;
        this.total = total;
      } catch (err) {
        console.log("ajax ajaxGetSaleList err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 售后创建日期发生变化
     */
    changeCreateDate(data) {
      this.formData.create_time = `${data?.[0] || ""},${data?.[1] || ""}`;
      // this.formData.create_start_time = data?.[0] || "";
      // this.formData.create_end_time = data?.[1] || "";
    },
    /**
     * 售后完成日期发生变化
     */
    changeFinishDate(data) {
      this.formData.operator_at = `${data?.[0] || ""},${data?.[1] || ""}`;
      // this.formData.finish_start_time = data?.[0] || "";
      // this.formData.finish_end_time = data?.[1] || "";
    },
    /**
     * 支付日期发生变化
     */
    changePayDate(data) {
      this.formData.pay_at = `${data?.[0] || ""},${data?.[1] || ""}`;
      // this.formData.pay_start_time = data?.[0] || "";
      // this.formData.pay_end_time = data?.[1] || "";
    },
    /**
     * 供货日期发生变化
     */
    changeSupplierDate(data) {
      this.formData.supplier_time = `${data?.[0] || ""},${data?.[1] || ""}`;
      // this.formData.supplier_start_time = data?.[0] || "";
      // this.formData.supplier_end_time = data?.[1] || "";
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      }
    }, 500),
    /**
     * 获取自提点列表
     */
    ajaxGetSelfTakeList: debounce(async function ({
      name = "",
      logisticsId = "",
    }) {
      /**
       * 城市仓为必传参数
       * 防止接口在删除城市仓前请求数据，故先判断条件
       */
      if (!logisticsId || !this.formData.logistics_id) {
        return;
      }
      const params = {
        name,
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.deliveryList(params);
        this.selfTakeList = data.data;
        console.log("ajx ajaxGetSelfTakeList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetSelfTakeList err", err);
      }
    },
    500),
    /**
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      // 重置自提点
      this.selfTakeList = [];
      this.formData.delivery_id = "";
      const params = {
        name: "",
        logisticsId: data,
      };
      this.ajaxGetSelfTakeList(params);
    },
    /**
     * 搜索数据项
     */
    onFilterMethod(type, data) {
      if (type === "cityStore") {
        this.ajaxGetCityStoreList(data);
      }
      if (type === "selfTake") {
        const params = {
          name: data,
          logisticsId: this.formData.logistics_id,
        };
        this.ajaxGetSelfTakeList(params);
      }
    },
    /**
     * 失焦时，初始化下拉框
     */
    onChangeVisible(type, data) {
      if (!data && type === "cityStore") {
        this.ajaxGetCityStoreList();
      }
      if (!data && type === "selfTake") {
        const params = {
          name: "",
          logisticsId: this.formData.logistics_id,
        };
        this.ajaxGetSelfTakeList(params);
      }
    },
  },
};
</script>

<style lang="scss">
.follow-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .order-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    background: #ffffff;
    padding: 20px;
    .inputs {
      display: flex;
      .label {
        line-height: 38px;
      }
    }
  }
  .title {
    background: #ffffff;
    box-sizing: border-box;
    display: flex;
    .bjsearch {
      background: #333333;
      border-color: #333333;
      margin-right: 10px;
    }
  }
  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
/deep/ .el-select {
  width: 240px;
  height: 36px;
  .el-input {
    width: 100%;
    height: 100%;
    &__inner {
      width: 100%;
      height: 100% !important;
    }
  }
}
</style>
